<template>
  <div class="all_main_wrap">
    <!-- 头部 -->
    <div class="all_header_box">
      <div class="sider_left">
        <span class="title">
          <router-link class="crumbs_item" tag="a" to="/admin/UserManage/AccountCreat">账号管理 / </router-link>
          <span class="crumbs_item crumbs_last">新建账号</span>
        </span>
      </div>
    </div>
    <!-- 功能区 -->
    <div class="all_content_box">
      <div class="line_item" v-show="operation == 1">
        <div class="side_inner">
          <span class="all_left_name all_required">姓名</span>
          <a-input class="all_input" v-model="form.name" placeholder="请输入姓名" />
        </div>
        <div class="side_inner">
          <span class="all_left_name all_required">用户名</span>
          <a-input class="all_input" v-model="form.userName" placeholder="请输入用户名" />
        </div>
      </div>

      <div class="line_item" v-show="operation == 1">
        <div class="side_inner">
          <span class="all_left_name all_required">手机号</span>
          <a-input class="all_input" v-model="form.phone" placeholder="请输入手机号" />
        </div>
        <div class="side_inner">
          <span class="all_left_name all_required">密码</span>
          <a-input-password class="input-password" v-model="form.password" placeholder="请设置密码" />
        </div>
      </div>
      <div class="icon">
        状态：
        <span>
          <a-icon type="apartment" style="color:#FF8C00" />
          父级节点
        </span>
        <span>
          <a-icon type="menu-unfold" style="color:#15b7dd" />
          菜单权限
        </span>
        <span>
          <a-icon type="paper-clip" style="color:#32CD32" />
          按钮权限
        </span>
      </div>
      <div class="line_item power_item">
        <a-spin :spinning="loading">
        <div class="spin-content">
          <a-tree
            ref="tree"
            v-if="menuList && menuList.length > 0" 
            :tree-data="menuList"
            checkable
            @check="getHalfCheckedKeys"
            :icon="getIcon"
            :showIcon="true"
            class="tree"
            :replaceFields="{ key:'menuId',title:'menuName',children:'sysMenuList'}"
            v-model="form.idMenuList"
          >
          </a-tree>
        </div>
      </a-spin>
      </div>
    </div>
    <div class="button">
      <a-button type="primary" v-if="operation == 1" :loading="loading" @click="onSaveForm" class="bt">创建</a-button>
      <a-button type="primary" v-else :loading="loading" @click="onModifyMenu" class="bt">修改</a-button>
      <a-button class="all_boder_btn bt" @click="$router.go(-1)">取消</a-button>
    </div>
  </div>
</template>

<script>
  export default {
    // 可用组件的哈希表
    components: {},
    // 接收传值
    props: {},
    // 数据对象
    data() {
      return {
        operation:'',
        menuList:[], //菜单树
        loading:false,
        form:{
          userId:'',
          name:'',
          userName:'',
          phone:'',
          password:'',
          idMenuList:[]
        },
        halfCheckedKeys: [], // 父级id
        checkedAllIds: [], // 全选ids
        isFirst: true,
      };
    },
    // 事件处理器
    methods: {
      // 保存
      onSaveForm(){
        // 非空校验 姓名
        if(!this.form.name){
          this.$message.warning('请输入姓名');
          return
        }
        // 非空校验 用户名
        if(!this.form.userName){
          this.$message.warning('请输入用户名');
          return
        }
        // 非空校验 手机号
        if(!this.form.phone){
          this.$message.warning('请输入手机号');
          return
        }
        // 非空校验 密码
        if(!this.form.password){
          this.$message.warning('请输入密码');
          return
        }
        if(!this.$regular.password.reg.test(this.form.password)){
          this.$message.warning(this.$regular.password.msg);
          return
        }

        // 非空校验 权限
        if(!this.form.idMenuList.length){
          this.$message.warning('请至少选择一项用户权限');
          return
        }

        let ids = [...this.form.idMenuList, ...this.halfCheckedKeys]

        this.loading = true
        this.$ajax({
          method: 'post',
          url: "/hxclass-management/user/info/add/manage" ,
          params: {
            idMenuList: ids,
            name: this.form.name,
            password: this.form.password,
            phone: this.form.phone,
            userId: this.form.userId,
            userName: this.form.userName,
          }
        }).then(res => {
          this.loading = false
          if (res.code == 200 && res.success) {
            this.$message.success(res.message);
            this.$router.go(-1)
          } else{
            this.$message.warning(res.message);
          }
        }).catch(error => {
          this.loading = false
          if (error) {
            this.$message.warning('系统错误');
          }
        })
      },

      getIcon(props) {
        if(props.menuType == 1){ // 菜单节点
          return <a-icon type="menu-unfold" style="color:#15b7dd" />;
        } else if(props.menuType == 2) {  // 按钮节点
          return <a-icon type="paper-clip" style="color:#32CD32" />;
        } else { // 父级节点
          return <a-icon type="apartment" style="color:#FF8C00" />;
        }
      },

      getHalfCheckedKeys(e,v){
        this.isFirst = false
        this.halfCheckedKeys = v.halfCheckedKeys
      },

      // 获取菜单列表
      getMenuList(){
        this.loading = true
        this.$ajax({
          method: 'get',
          url:"/hxclass-management/menu/all/byUser",
          params: {
            userId: this.$route.query.id ? this.$route.query.id : undefined
          }
        }).then(res => {
          this.loading = false
          if (res.code == 200 && res.success) {
            this.menuList = res.data
            let ids= [] // 存放已分配的权限id
            if(this.$route.query.id){
              // 课程目录
              function fors(arr) {
                if(!arr) {
                  return []
                }
                arr.forEach((item) => {
                  if(item.possess){
                    ids.push(item.menuId)
                  }
                  if (item.sysMenuList && item.sysMenuList.length > 0) {
                    fors(item.sysMenuList);
                  }
                });
                return arr;
              }
              fors(res.data);
              this.checkedAllIds = ids
            }
          }
        })
      },

      // 获取菜单列表
      getIds(){
        this.loading = true
        this.$ajax({
          method: 'get',
          url:"/hxclass-management/menu/all/byUser/checked",
          params: {
            userId: this.$route.query.id
          }
        }).then(res => {
          this.loading = false
          if (res.code == 200 && res.success) {
            this.form.idMenuList = res.data
          }
        })
      },

      // 确认修改权限
      onModifyMenu(){
        // 非空校验 权限
        if(!this.form.idMenuList.length){
          this.$message.warning('请至少选择一项用户权限');
          return
        }

        let ids = []
        if(this.isFirst){
          ids = this.checkedAllIds
        } else {
          ids = [...this.form.idMenuList, ...this.halfCheckedKeys]
        }

        this.loading = true
        this.$ajax({
          method: 'put',
          url: "/hxclass-management/menu/user" ,
          params: {
            userId: this.$route.query.id,
            userMenuIds: ids
          }
        }).then(res => {
          this.loading = false
          if (res.code == 200 && res.success) {
            this.$message.success(res.message);
            this.$router.go(-1)
          } else{
            this.$message.warning(res.message);
          }
        }).catch(error => {
          this.loading = false
          if (error) {
            this.$message.warning('系统错误');
          }
        })
      },
    },
    // 生命周期-实例创建完成后调用
    created() {
      this.operation = this.$route.query.operation;
    },
    // 生命周期-实例挂载后调用
    mounted() {
      this.getMenuList()
      if(this.$route.query.id){
        this.getIds()
      }
    },
    // 生命周期-实例销毁离开后调用
    destroyed() {},
    // 计算属性监听
    computed: {},
    // 自定义的侦听器
    watch: {},
  };

</script>

<style lang="less" scoped>
  a {
    color: #333333;
  }

  .all_content_box {
    padding: 20px 0;

    .line_item {
      display: flex;
      width: 900px;
      margin-bottom: 30px;
      justify-content: space-between;

      .side_inner {
        display: flex;
        width: 400px;

        .all_left_name {
          width: 100px;
        }

        .all_input {
          width: 270px;
        }

        .input-password {
          width: 270px;

          /deep/.ant-input {
            height: 40px;
          }
        }
      }
    }

    .power_item {
      width: 100%;

      .side_inner {
        width: 100%;

        .table_list {
          flex: 1;

          table {
            width: 100%;
            border-collapse: collapse;
            border: 1px solid #eeeeee;
            overflow-x: scroll;

            tr {
              height: 45px;
              border-bottom: 1px solid #eeeeee;
            }

            th,
            td {
              font-size: 14px;
              font-weight: 500;
              color: #333333;
            }

            td {
              font-size: 12px;
              >span {
                display: inline-block;
                min-width: 100px;
              }
            }

            .header {
              width: 100%;
              background: #f6f6fc;
            }
          }

          table {
            table-layout: fixed;
          }

          table tr th:first-child,
          table tr td:first-child {
            width: 170px;
            padding-left: 30px;
            border-right: 1px solid #eeeeee;
          }

          table tr th:last-child,
          table tr td:last-child {
            padding-left: 20px;
          }
        }
      }
    }

    .icon{
      margin-bottom: 15px;
      >span{
        margin-right: 30px;
      }
    }
  }

  .button {
    .bt {
      margin: 24px 14px;
    }
  }

</style>
